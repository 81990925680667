import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

interface ISeoProps {
  title?: string;
  description?: string;
  image?: string;
  article?: boolean;
  facebook?: string;
  twitter?: string;
  instagram?: string;
  keywords?: string[];
}

const SEO = ({ title, description, image, article }: ISeoProps) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
    facebook,
    instagram,
    keywords,
  } = site.siteMetadata;

  const seo = {
    facebook,
    instagram,
    keywords,
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <Helmet titleTemplate={`%s - Alex Decor`}>
      <html lang="en" />
      <title>{title}</title>
      {seo.image && <meta property="og:image" content={seo.image} />}

      {/* Meta Tags */}
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content={seo.description} />
      {seo.keywords && <meta name="keywords" content={seo.keywords} />}

      {/* Open Graph */}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.title && (
        <meta property="og:site_name" content={seo.title} key="ogsitename" />
      )}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      {(article ? true : null) && <meta property="og:type" content="article" />}
    </Helmet>
  );
};

export default SEO;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        siteUrl
        defaultTitle: title
        defaultDescription: description
        defaultImage: image
        facebook
        instagram
        linkedin
        keywords
      }
    }
  }
`;
